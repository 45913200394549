<template>
  <div>
    <v-tabs
      grow
      show-arrows
      center-active
      v-model="geraet"
      background-color="red lighten-5"
    >
      <template v-for="d in disciplines">
        <v-tab
          v-if="ids.every(id => isWkDiscipline(id, d._id))"
          :key="d._id"
        >
          {{ d.order }}. {{ d.name }}
        </v-tab>
      </template>
    </v-tabs>

    <v-tabs-items v-model="geraet" touchless>
      <template v-for="d in disciplines">
        <v-tab-item
          v-if="ids.every(id => isWkDiscipline(id, d._id))"
          :key="d._id"
        >
          <geraet :df="df" :did="d._id" :teams="mAnG(d._id)" :r="ResultFind" />
        </v-tab-item>
      </template>
    </v-tabs-items>
  </div>
</template>

<script>
import { useAuth } from '@/plugins/auth'
import { query, resultquery } from '@/views/components/stb_m_2021/content/input/query'
import gql from 'graphql-tag'
import Vue from 'vue'
import { deleteQuery } from '@/plugins/graphql'
import { useCalc } from '@/views/components/stb_m_2021/plugins/calc'

export default {
  name: 'bei',

  components: {
    Geraet: () => import('./kombiniert/geraet')
  },

  setup (props, context) {
    return {
      ...useAuth(context),
      ...useCalc()
    }
  },

  props: {
    ids: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    Events: {},
    ResultFind: {},
    geraet: 0
  }),

  computed: {
    disciplines () {
      return this.events?.[0]?.disciplines?.slice().sort((a, b) => a.order < b.order ? -1 : 1).map(d => ({
        ...this.df.find(df => df._id === d._discipline),
        order: d.order
      })).filter(d => d.regular)
    },
    events () {
      return Object.values(this.Events).filter(e => !!this.ids.find(id => e._id === id))
    }
  },

  methods: {
    mAnG (did) {
      return this.events
        .map(e => this.mannschaftenangeraet(e, this.df, did).map(m => ({ ...m, e })))
        .reduce((acc, curr) => { acc.push(...curr); return acc }, [])
        .sort((a, b) => a.df < b.df ? -1 : 1)
    },
    fetch (id) {
      this.$apollo.query({
        query: gql`query($id: UUID!) {
          Event(id: $id) { ${query} }
        }`,
        variables: {
          id
        }
      }).then(({ data }) => {
        Vue.set(this.Events, id, data.Event)
      })

      this.$apollo.query({
        query: gql`query($type: ResultType!, $event: UUID!) {
          ResultFind(type: $type, event: $event) { ${resultquery} }
        }`,
        variables: {
          type: 'stb_m_2021_wk_team_athlete',
          event: id
        },
        fetchPolicy: 'no-cache'
      }).then(({ data }) => {
        Vue.set(this.ResultFind, id, data.ResultFind)
      })

      this.$apollo.subscribe({
        query: gql`subscription($type: ResultType!, $event: UUID!) {
            ResultCreate(type: $type, event: $event) { ${resultquery} }
          }`,
        variables: {
          type: 'stb_m_2021_wk_team_athlete',
          event: id
        }
      }).subscribe({
        next: ({ data }) => {
          this.ResultFind[id].push(data.ResultCreate)
        }
      })

      this.$apollo.subscribe({
        query: gql`subscription($type: ResultType!, $event: UUID!) {
          ResultUpdate(type: $type, event: $event) { ${resultquery} }
        }`,
        variables: {
          type: 'stb_m_2021_wk_team_athlete',
          event: id
        }
      }).subscribe({
        next: ({ data }) => {
          const i = this.ResultFind[id].findIndex(r => r._id === data.ResultUpdate._id)
          Vue.set(this.ResultFind[id], i, data.ResultUpdate)
        }
      })

      this.$apollo.subscribe({
        query: gql`subscription($type: ResultType!, $event: UUID!) {
          ResultDelete(type: $type, event: $event)
        }`,
        variables: {
          type: 'stb_m_2021_wk_team_athlete',
          event: id
        }
      }).subscribe({
        next: (i) => {
          deleteQuery('ResultFind', 'ResultDelete', this.ResultFind[id], i)
        }
      })
    },
    fetchData () {
      this.ids.forEach(id => {
        this.fetch(id)
        this.$apollo.subscribe({
          query: gql`subscription($id: UUID!) {
            EventUpdate(id: $id) { ${query} }
          }`,
          variables: {
            id
          }
        }).subscribe({
          next: ({ data }) => {
            Vue.set(this.Events, data.EventUpdate._id, data.EventUpdate)
          }
        })
      })
    }
  },

  created () {
    this.fetchData()
  },

  watch: {
    id () {
      this.fetchData()
    }
  }
}
</script>

<style scoped>

</style>
